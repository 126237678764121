// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$ClientApp-primary: mat.define-palette(mat.$indigo-palette);
$ClientApp-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$ClientApp-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$ClientApp-theme: mat.define-light-theme(
  (
    color: (
      primary: $ClientApp-primary,
      accent: $ClientApp-accent,
      warn: $ClientApp-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($ClientApp-theme);

@import "assets/scss/variables";
/* You can add global styles to this file, and also import other style files */
$page_max_size: 1200px;

@for $i from 1 through 4 {
  .bg-primary.bg-light-#{$i} {
    background-color: lighten($color-primary, $i * 7%) !important;
  }

  .bg-success.bg-light-#{$i} {
    background-color: lighten($color-success, $i * 7%) !important;
  }

  .bg-warning.bg-light-#{$i} {
    background-color: lighten($color-warning, $i * 7%) !important;
  }

  .bg-danger.bg-light-#{$i} {
    background-color: lighten($color-danger, $i * 7%) !important;
  }
}
.disabled {
  pointer-events: none;
}

$header_height: 45px;
$menu_padding: 8px;
$footer_height: 37px;

main {
  max-width: $page_max_size;
  margin: auto;

  .main-container {
    min-height: calc(100vh - 64px);
  }

  @media (min-width: 768px) {
    .main-container {
      min-height: calc(100vh - 12px);
    }
  }
}

.avatar > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.no-outer-border {
  border-left: 0 !important;
  border-top: 0 !important;
  border-bottom: 0 !important;

  &:last-child {
    border-right: 0 !important;
  }
}

a[disabled] {
  pointer-events: none;
}

.spinner-grow {
  width: 1em !important;
  height: 1em !important;
}

.pagination-wrapper {
  overflow-x: auto;
}

@media (min-width: 768px) {
  .pagination-wrapper {
    max-width: calc(100% - 202px);
  }
}

// ng-select
.ng-select {
  .ng-select-container {
    min-height: 24px !important;
    height: 24px !important;
    border: none !important;

    .ng-value-container {
      padding-left: 0 !important;

      .ng-value {
        .ng-value-label {
          .small {
            display: none;
          }
        }
      }

      .ng-input {
        top: 0 !important;
        left: 0 !important;
        padding-left: 0 !important;

        input {
          cursor: text !important;
        }
      }
    }
  }

  .ng-dropdown-panel {
    border: none !important;
  }
}

.ng-select {
  height: 50px !important;

  .ng-select-container {
    border-radius: 8px !important;
    border: 1px solid #d0d5dd !important;
    height: 40px !important;

    .ng-value-container {
      padding-left: 12px !important;

      .ng-value {
        border-radius: 6px !important;
        border: 1px solid #d0d5dd !important;
        background-color: white !important;

        .ng-value-icon {
          border: 0px !important;
        }
      }
    }
  }
}

app-datalist-select.app-datalist-select-double-height {
  .ng-select {
    height: calc(8rem + 4px);

    .ng-select-container {
      height: calc(5.75rem + 4px) !important;
      align-items: flex-start;
    }
  }
}

.form-floating.is-invalid .ng-select .ng-select-container {
  border: 1px solid #fda29b;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #fee4e2;
}

app-users-datalist-select {
  .ng-select {
    height: 50px !important;

    .ng-select-container {
      border-radius: 8px !important;
      border: 1px solid #d0d5dd !important;
      height: 40px !important;

      .ng-value-container {
        padding-left: 12px !important;

        .ng-value {
          border-radius: 6px !important;
          border: 1px solid #d0d5dd !important;
          background-color: white !important;

          .ng-value-icon {
            border: 0px !important;
            padding-right: 2px !important;
          }
        }
      }
    }
  }
}

.bs-tooltip-start.tooltip {
  padding-top: 9px;
}

// Static menu version of the content
$menu_open_size: 190px;
$menu_small_size: 190px;

@media (min-width: 768px) {
  body.has-expandable-menu {
    &.menu-expanded {
      overflow: auto;
    }

    app-headermenu {
      app-user-card {
        display: none;
      }
    }

    .sidebar-wrap {
      left: 0;
      opacity: 1;
      width: $menu_open_size;

      .sidebar {
        left: 0;
        width: $menu_open_size;
      }
    }

    main.home-layout {
      padding-left: $menu_open_size;
      max-width: none;
    }

    app-staticfooter,
    app-headermenu {
      padding-left: $menu_open_size;
    }

    &:not(.menu-expanded) {
      .sidebar-wrap {
        width: $menu_small_size;

        .sidebar {
          width: $menu_small_size;

          .nav {
            .dropdown-menu {
              min-width: 0;
              padding: 0;
            }

            .nav-link {
              .icon {
                margin-right: 0;
              }
            }
          }
        }
      }

      main.home-layout {
        padding-left: $menu_small_size;
      }

      app-staticfooter,
      app-headermenu {
        padding-left: $menu_small_size;
      }
    }
  }
}

.dark-mode tspan {
  fill: #888888;

  &:first-child {
    fill: #ffffff;
  }
}

.top-20 {
  margin-top: -15px !important;
}

.dark-mode .log-information.bubble-sheet li p {
  background: #4b4d4e;
}

.dark-mode .log-information.bubble-sheet li p:before {
  border-right: 10px solid #4b4d4e;
}

.dark-mode .ng-select .ng-select-container {
  background-color: #000000;
  color: #ffffff;
}

.log-information {
  & > li {
    padding-right: 0;
  }
}

.dark-mode .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background-color: #000000;
  color: #ffffff;
}

.dark-mode .ng-option.ng-option-marked {
  background-color: #000000 !important;
  color: #7297f8 !important;
}

.dark-mode .ng-option.ng-option-selected {
  background-color: #4b4d4e !important;
  color: #ffffff !important;
}

.dark-mode .ng-select .ng-select-container {
  background-color: #000000 !important;
  color: #ffffff;
}

// full calendar
.fc-event-title-container {
  height: 5px !important;
}

.fc-daygrid-day-frame {
  display: flex !important;
  flex-direction: column;
  align-items: center !important;
  justify-content: space-between;
}

.fc-daygrid-day-top {
  margin-top: 10px !important;
}

.fc-daygrid-day-bottom {
  display: none !important;
}

.fc-daygrid-day-events {
  min-height: 10px !important;
  overflow: hidden !important;
  overflow-x: scroll !important;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-height: 700px) {
  .fc-daygrid-day-number {
    padding-top: 0 !important;
    align-self: auto;
  }
}

.fc-day-today {
  margin-top: 100px !important;
  border-radius: 20px !important;
  background-color: transparent !important;
  height: 80% !important;
}

.fc-highlight {
  margin: 8px;
  border-radius: 20px;
  background-color: #f9fafb !important;
}

.fc td {
  padding: 0;
  vertical-align: middle !important;
}

#calendar td {
  vertical-align: middle !important;
}

.fc {
  border: 1px solid #eaecf0;
  border-radius: 8px !important;
  overflow: hidden;
  text-align: center !important;

  a {
    text-decoration: none;
  }

  .fc-toolbar-title {
    color: #344054;
    margin: 0 !important;
    font-size: 16px !important;
  }

  .fc-button {
    padding: 0.275em 0.475em;
    font-size: 0.95em;
    line-height: 1.7;
    background-color: #e8e1e1;
    border-color: white;
  }

  .fc-toolbar.fc-header-toolbar {
    -moz-border-radius-topleft: 8px;
    -moz-border-radius-topright: 8px;
    margin-bottom: 0em !important;
    padding: 10px 15px !important;
    background-color: transparent;

    .fc-button {
      color: #667085;
      background-color: transparent;
      border-color: transparent;

      &:focus {
        box-shadow: none;
        outline: none;
      }
    }
  }

  &.fc-theme-standard {
    table {
      border-collapse: collapse;
      /* Shadow/xl */
      border-radius: 8px !important;

      th {
        font-size: 14px;
        text-decoration: none;
        line-height: 20px;
        border: none;
        background-color: transparent;

        a.fc-col-header-cell-cushion {
          color: black;
          font-weight: normal;
          padding: 10px !important;
        }
      }

      td {
        border: none;
      }

      table {
        td {
          background-color: transparent;
          color: inherit;
          font-size: 14px;
          text-decoration: none;
          padding: 0;

          a {
            color: inherit;
            font-weight: normal;
          }

          .fc-daygrid-more-link {
            font-size: 10px;
          }

          .fc-daygrid-day-events {
            margin-top: -8px;
          }

          &:first-child {
            border-left: none;
          }

          &:last-child {
            border-right: none;
          }
        }

        tr:first-child {
          td {
            border-bottom: none;
          }
        }

        tr:last-child {
          td {
            border-bottom: none;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .fc.fc-theme-standard table table td .fc-daygrid-more-link {
    height: 8px;
    width: 8px;
    border-radius: 8px;
    text-indent: 15px;
    overflow: hidden;
    display: block;
  }
}

.form-floating > .form-control[type="file"] {
  padding-top: 1.75rem;
}

// Helpers from bootstrap 5.1
.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: 0.25;
}

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.ck-editor {
  border: 1px solid #d0d5dd !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
  border-bottom-right-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.ck-sticky-panel__content {
  border-top-left-radius: 8px !important;
}

.ck-rounded-corners {
  border-radius: 8px !important;
}

.ck-editor__main {
  .ck-rounded-corners {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }
}

.ck-toolbar_grouping {
  border: 0 !important;
  border-radius: 10px !important;
}

.ck-editor__editable {
  min-height: 140px;
  min-width: 100%;
  --ck-border-radius: 5px;
  --ck-color-base-border: #ffffff;
}

.ck-button_with-text {
  font-family: "Inter", sans-serif !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 24px !important;
}

.ck-toolbar_grouping {
  margin: 0% !important;
  padding: 0% !important;
  width: 100% !important;
}

.ck-toolbar__items {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  padding: 10px !important;
  background-color: #f2f4f7 !important;
}

.ck-button__icon {
  color: #98a2b3 !important;
}

.ck-dropdown__button {
  font-weight: 500px !important;
  background-color: white !important;
  border: 1px solid #d0d5dd !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
  border-radius: 8px !important;
}

.ck-focused {
  border: none !important;
}

.ck.ck-toolbar {
  border: 0;
  --ck-color-toolbar-border: #ffffff;
}

.pointer {
  cursor: pointer !important;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.cdk-overlay-backdrop {
  z-index: 998 !important;
  background: rgba(0, 0, 0, 0.4) !important;
  backdrop-filter: blur(5px) !important;
  transition: none !important;
}

.mat-dialog-container {
  border-radius: 20px !important;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.enableUrl {
  cursor: pointer;
  color: #0d6efd;
  text-decoration: underline;
}

.brdNotFirstChild:not(:first-child) {
  border-top: 1px solid #d5d7db;
}

.treeview-menu {
  position: relative;
}

.treeview-menu::before {
  content: "";
  color: transparent;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 19px;
  margin-left: -20px;
  border-left: 1px solid black; // vertical connectors
  height: 30px;
}

.treeview-menu-half::before {
  height: 10px !important;
}

.treeview-menu {
  .treeview-item::before {
    content: "";
    color: transparent;
    position: absolute;
    top: 10px;
    margin-left: -20px;
    width: 15px;
    border-top: 1px solid black;
  }
}

.last-event:last-child {
  .treeview-menu::before {
    height: 10px !important;
  }
}

.last-event:hover {
  background-color: #e5e5e5;
}

.removeDoubleRow {
  .doubleRowM {
    margin-top: 20px;
  }

  .doubleRowP {
    padding: 2px !important;
  }
}

.responsiveTop {
  padding-top: 12px;
}

.b-radius {
  border-radius: 20px;
  overflow: hidden;
}

.b-top-left-radius {
  border-top-left-radius: 20px;
  overflow: hidden;
}

.dark-wfrBlue {
  color: #0b5087;
  background-color: #0b5087;
}

// .emojiFonts {
//   font-family: "Segoe UI Emoji", "Segoe UI Symbol", "Segoe UI",
//     "Apple Color Emoji", "Twemoji Mozilla", "Noto Color Emoji", "Android Emoji",
//      !important;
// }

.emojiFonts {
  font-family: "Inter", "Segoe UI Emoji", "Segoe UI Symbol", "Segoe UI",
    "Apple Color Emoji", "Twemoji Mozilla", "Noto Color Emoji", "Android Emoji" !important;
}
// .emojiFonts {
//   font-family: "Noto Color Emoji" !important;
// }

.pointerButton {
  cursor: pointer;
  border-radius: 12px;
}

.pointerButton:hover {
  background-color: #cccccc;
}

.whiteColorFilter {
  filter: brightness(1000%) !important;
}

.elipsesOverflow {
  width: 100%;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-height: 30px;
}
.h-30px {
  height: 30px !important;
}

.evenBg:nth-child(even) {
  background-color: #f5f6f7;
}
.posSticky {
  position: sticky !important;
  top: 0 !important;
}
.es-box-name-header {
  border: 0px solid #d5d7db;
  border-radius: 12px 12px 0px 0px;
  height: 50px;
  background: #f9fafb;
  border-bottom: 1px solid #d5d7db;
  z-index: 100;
}
.mat-mdc-snack-bar-container {
  &.error-snackbar {
    --mdc-snackbar-container-color: #dc3545;
    --mat-mdc-snack-bar-button-color: white;
    --mdc-snackbar-supporting-text-color: white;
    // --mdc-snackbar-container-width: 100%;
    // width: 8000px !important;
  }
}
.mat-mdc-simple-snack-bar .mdc-snackbar__label {
  max-width: 600px !important;
  overflow: hidden !important;
  display: inline-block !important;
  text-overflow: ellipsis !important;
  max-height: 55px !important;
}
@import url("https://fonts.cdnfonts.com/css/flags-color");
@import "@ctrl/ngx-emoji-mart/picker";
@import url("https://fonts.googleapis.com/css2?family=Noto+Color+Emoji&display=swap");
.invalid-hui {
  .ngx-timepicker {
    border: 1px solid #fda29b !important;
    border-radius: 8px;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #fee4e2;
  }
}

.double-session-first {
  position: relative;
}
.double-session-first::before {
  content: "";
  position: absolute;
  width: 8px;
  height: 32px;
  top: 50%;
  left: -12px;
  border: 2px solid black;
  border-right: 0;
}

.double-session-first::after {
  content: "";
  // position: absolute;
  width: 8px;
  height: 32px;
  // top: 50%;
  // left: 100px;
  margin-top: 32px;
  margin-left: 5px;
  border: 2px solid black;
  border-left: 0;
}

@import "ckeditor5/ckeditor5.css";
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap");
.ck-toolbar__items {
  background: none !important;
  padding: 5px 10px !important;
}
.ck.ck-editor {
  flex-grow: 1 !important;
  display: flex;
  flex-flow: column;
  .ck-editor__main {
    flex-grow: 1 !important;
  }
}

.ck-editor__top {
  .ck-sticky-panel {
    .ck-sticky-panel__content {
      border: none !important;
      border-bottom: 1px solid #d0d5dd !important;

      .ck-dropdown {
        height: 100% !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        cursor: pointer;
        margin-right: 7px;
      }
      .ck-dropdown__panel {
        margin-top: 5px !important;
        border-radius: 6px !important;
        .ck-toolbar_vertical {
          border-radius: 6px !important;
          .ck-toolbar__items {
            padding: 0 !important;
          }
        }
        .ck-list {
          border-radius: 6px !important;
          padding: 0 !important;
        }
        .ck-button {
          border-radius: 6px !important;
          border: none !important;
          cursor: pointer !important;
          &:not(:last-child) {
            margin-bottom: 4px !important;
          }
        }
      }
      .ck-button {
        border-radius: 6px;
        border: 1px solid #d0d5dd;
        cursor: pointer;
        margin-right: 7px;
        &:focus,
        &:active {
          box-shadow: none;
        }
      }
      .ck-on {
        background-color: #ececec;
      }
    }
  }
}
.ck-editor__editable {
  &.ck-editor__editable_inline {
    border: none !important;
  }
  &.ck-focused {
    box-shadow: none !important;
    border: none !important;
  }
  border: none;
  min-height: 250px;
  height: 100% !important;
  max-height: 75vh;
  overflow-y: scroll;
  box-sizing: border-box;

  p,
  ul,
  li,
  ol {
    font-size: 18px !important;
  }

  h1 {
    font-size: 30px !important;
  }
  h2 {
    font-size: 26px !important;
  }
  h3 {
    font-size: 24px !important;
  }
  h4 {
    font-size: 18px !important;
  }

  h1,
  h2,
  h3,
  h4 {
    font-family: "Inter", sans-serif;
    margin-bottom: 1.2rem;
  }
}
